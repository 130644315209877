require('./bootstrap');
import Vue from "vue";
import {Tooltip} from "bootstrap";
import Suggestions from "v-suggestions";
import 'v-suggestions/dist/v-suggestions.css';


window.Vue = Vue;

Vue.config.productionTip = false;
Vue.component('suggestions', Suggestions)

const initApp = () => import ( /* webpackChunkName: "InitApp" */ './components/Init.vue');
const carbonCompareApp = () => import ( /* webpackChunkName: "InitApp" */ './components/CarbonCompare');

new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
});

const app = new Vue({
    el: '#app',
    components: {
        "init-app": initApp,
        "carbon-compare": carbonCompareApp,
    }
});
